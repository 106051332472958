@tailwind base;
@tailwind components;
@tailwind utilities;

/* ************** LOGIN BACKGROUND IMAGE *************** */

.login-background {
  background-image: url("./assets/login-bg.png");
  background-size: cover;
  background-position: center;
}

/* ************** LOADER *************** */

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #1d2226;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
